import amsterdamTheme from 'gatsby-theme-amsterdam/src/gatsby-plugin-theme-ui'

export default {
  ...amsterdamTheme,
  colors: {
    ...amsterdamTheme.colors,
    background: '#9AAB8E',  // Change this to your desired background color
    text: '#F8F8FF',  // Change this to your desired text color
    modes: {
      dark: {
          background: '#9AAB8E',
          text: '#F8F8FF',
      },
    },
  },
}
