module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/Users/nearpoduser/repos/the-way-of-gardens/node_modules/gatsby-remark-prismjs","id":"96900835-4b61-53a8-8a41-f46b0b717fb0","name":"gatsby-remark-prismjs","version":"6.25.0","modulePath":"/Users/nearpoduser/repos/the-way-of-gardens/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/nearpoduser/repos/the-way-of-gardens/node_modules/gatsby-remark-images","id":"9df90587-bef6-51df-aed1-7a4c0717f3b4","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/Users/nearpoduser/repos/the-way-of-gardens/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1000,"linkImagesToOriginal":false,"withWebp":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/nearpoduser/repos/the-way-of-gardens","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"linkImagesToOriginal":false,"withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-amsterdam/gatsby-browser.js'),
      options: {"plugins":[],"colorToggle":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Way of Gardens","short_name":"the Way of Gardens","background_color":"#f5f0eb","theme_color":"#f5f0eb","start_url":"/","display":"standalone","icon":"/Users/nearpoduser/repos/the-way-of-gardens/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1f67e1e655206606e3915a59d62c887f"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
